import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ImeiBlacklist from './components/imei-blacklist-checker/IMEIBlacklist';
import SecurityPasscode from './components/security-passcode-unlocker/SecurityPasscode';
import ICloudActivation from './components/icloud-activation-lock-checker/iCloudActivation';
import NetworkIdentifier from './components/original-network-identifier/NetworkIdentifier';
import UnlockPhone from './components/unlock-phone/UnlockPhone';
import Success from './components/success/Success';
import Home from './components/home/Home';
import TrackOrder from './components/track-order/TrackOrder';
import PrivacyPolicy from './shared/PrivacyPolicy/PrivacyPolicy';
import TermsandConditions from './shared/T&C/T&c';
import RefundPolicy from './shared/RefundPolicy/RefundPolicy';
import DeliveryPolicy from './shared/DeliveryPolicy/DeliveryPolicy';
import ApplyRefund from './shared/ApplyRefund/ApplyRefund';
import Promotions from './shared/Promotions/Promotions';
import  Troubleshoot  from './shared/Troubleshoot/Troubleshoot';
import CodeEntryInstructions from './shared/CodeEntryInstructions/CodeEntryInstructions';
import AboutUs from './shared/AboutUs/AboutUs';
import Blogs from './shared/Blogs/Blogs';
import NewHowitWork from './shared/NewHowitWork/NewHowitWork';
import BlogDetails from './shared/Blogs/BlogDetails';
import RequestRefund from './shared/RefundPolicy/RequestRefund';
import AffiliatResellerHub from './shared/affiliate-reseller/AffiliatResellerHub';
import ImeiStatus from './components/imei-blacklist-checker/ImeiStatus';
import ContactPage from './shared/contactUs/contactPage';
import FaqPage from './shared/faq/faq';
import NewTestimonial from './shared/PageTestimonial/Testimonial';
import CheckOut from './components/checkout/CheckOut';
// const Home=lazy(() => import("./components/home/Home"))
// const ImeiBlacklist= lazy(() => import("./components/imei-blacklist-checker/IMEIBlacklist"));
// const SecurityPasscode= lazy(() => import("./components/security-passcode-unlocker/SecurityPasscode"));
// const ICloudActivation= lazy(() => import("./components/icloud-activation-lock-checker/iCloudActivation"));
// const NetworkIdentifier= lazy(() => import("./components/original-network-identifier/NetworkIdentifier"));
// const UnlockPhone= lazy(() => import("./components/unlock-phone/UnlockPhone"));
// const Success= lazy(() => import("./components/success/Success"));
// const TrackOrder= lazy(() => import("./components/track-order/TrackOrder"));
// const PrivacyPolicy= lazy(() => import("./shared/PrivacyPolicy/PrivacyPolicy"));
// const RefundPolicy= lazy(() => import("./shared/RefundPolicy/RefundPolicy"));
// const DeliveryPolicy= lazy(() => import("./shared/DeliveryPolicy/DeliveryPolicy"));
// const ApplyRefund= lazy(() => import("./shared/ApplyRefund/ApplyRefund"));
// const Promotions= lazy(() => import("./shared/Promotions/Promotions"));
// const TermsandConditions= lazy(() => import("./shared/T&C/T&c"));
// const Troubleshoot= lazy(() => import("./shared/Troubleshoot/Troubleshoot"));
// const CodeEntryInstructions= lazy(() => import("./shared/CodeEntryInstructions/CodeEntryInstructions"));
const NotFound= lazy(() => import("./components/404/404"));
const AppRouter = () => {
    return (
            <Routes>
                <Route path='/unlock-phone' element={<Home/>}></Route>
                <Route path='/review-order' element={<UnlockPhone />}></Route>
                <Route path='/imei-blacklist-checker' element={<ImeiBlacklist />}></Route>
                <Route path='/icloud-activation-lock-checker' element={<ICloudActivation />}></Route>
                <Route path='/original-network-identifier' element={<NetworkIdentifier />}></Route>
                <Route path='/security-passcode-unlocker' element={<SecurityPasscode />}></Route>
                <Route path='/success' element={<Success />}></Route>
                <Route path='/track-order' element={<TrackOrder />}></Route>
                <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
                <Route path='/terms-and-conditions' element={<TermsandConditions />}></Route>
                <Route path='/refund-policy' element={<RefundPolicy />}></Route>
                <Route path='/request-a-refund' element={<RequestRefund />}></Route>
                <Route path='/delivery-policy' element={<DeliveryPolicy />}></Route>
                <Route path='/apply-refund' element={<ApplyRefund />}></Route>
                <Route path='/promotions' element={<Promotions />}></Route>
                <Route path='/troubleshoot' element={<Troubleshoot />}></Route>
                <Route path='/code-entry-instruction' element={<CodeEntryInstructions />}></Route>
                
                <Route path='/about-us' element={<AboutUs/>}></Route>
                <Route path='/contact-us' element={<ContactPage/>}></Route>
                <Route path='/faq' element={<FaqPage/>}></Route>
                <Route path='/how-it-works' element={<NewHowitWork/>}></Route>
                <Route path='/testimonial' element={<NewTestimonial/>}></Route>
                <Route path='/blogs' element={<Blogs/>}></Route>
                <Route path='/blog-details/:id' element={<BlogDetails/>}></Route>
                <Route path='/affiliate-reseller' element={<AffiliatResellerHub/>}></Route>
                <Route path='/imei-status' element={<ImeiStatus/>}></Route>
                <Route path='/checkout' element={<CheckOut/>}></Route>


                <Route path="/pages-error404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/pages-error404" />} />
                <Route path="" element={<Navigate to="/unlock-phone" />} />
            </Routes>
    );
};

export default AppRouter;
