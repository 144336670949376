import React, { forwardRef } from "react";
import "./contactUs.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { postData } from "../../_services/contact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoading } from "../../_shared/LoadingContext";
import { useTranslation } from "react-i18next";
const ContactUs = forwardRef((props, ref) => {
  const { startLoading, stopLoading } = useLoading();
  const { t } = useTranslation();

  const contactSchema = Yup.object().shape({
    fullName: Yup.string().required(t("ContE1")),
    email: Yup.string()
      .email(t("ContE22"))
      .required(t("ContE21")),
    message: Yup.string().required(t("ContE3")),
  });
  return (
    <div ref={ref}>
      <div className=" contact-us">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 col-lg-5 px-md-5 position-relative">
              <h5 className="fw-medium">{t("ContH1")}</h5>
              <h2 className="fw-bold py-2 pe-5 pe-md-0 py-md-0">{t("ContH2")}</h2>
              <img
                loading="lazy"
                className="position-absolute end-0 bottom-0 d-none d-md-block"
                src="/images/form-arrow.png"
                alt=""
              />
            </div>
            <div className="col-md-8 col-lg-6 px-3 pt-3">
              <Formik
                initialValues={{
                  fullName: "",
                  email: "",
                  message: "",
                }}
                validationSchema={contactSchema}
                onSubmit={(values, { resetForm }) => {
                  // same shape as initial values
                  console.log(values);
                  postData("contact", values, { method: "POST" })
                    .then((res) => {
                      console.log("Response:", res);
                      resetForm();
                      toast.success(res.message);

                      // Do something with the response
                    })
                    .catch((error) => {
                      // Handle error
                      toast.error(error.message);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder={t("ContF1")}
                        className="form-control form-control-lg"
                        id="fullName"
                        name="fullName"
                        value={values.fullName}
                        onChange={handleChange}
                      />
                      {errors.fullName && touched.fullName ? (
                        <span style={{ color: "red" }}>{errors.fullName}</span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        placeholder={t("ContF2")}
                        className="form-control form-control-lg"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email ? (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        placeholder={t("ContF3")}
                        cols="30"
                        rows="5"
                        id="message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && touched.message ? (
                        <span style={{ color: "red" }}>{errors.message}</span>
                      ) : null}
                    </div>
                    <button type="submit" className="btn w-100">
                      {t('ContB1')}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContactUs;
