import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UnlockPhone from "../unlock-phone/UnlockPhone";
import { useLocation } from "react-router-dom";

const CheckOut = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [productData, setProductData] = useState(null);
  const [formValue, setFormValue] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const productDataString = queryParams.get("productData");
    const formValueString = queryParams.get("formValue");

    try {
      const parsedProductData = productDataString ? JSON.parse(decodeURIComponent(productDataString)) : null;
      const parsedFormValue = formValueString ? JSON.parse(decodeURIComponent(formValueString)) : null;

      setProductData(parsedProductData);
      setFormValue(parsedFormValue);
    } catch (error) {
      console.error("Error parsing query parameters:", error);
    }
  }, [search]);

  return (
    <section className="banner py-5">
      <div className="container py-md-5 pt-5">
        {productData && <UnlockPhone productData={productData} formValue={formValue} />}
      </div>
    </section>
  );
};

export default CheckOut;
