// Spinner.js
import React ,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import "./spinner.css";
import ClipLoader from "react-spinners/DotLoader";
import { useLoading } from './LoadingContext';
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  
};


const Spinner = () => {
  const [progress, setProgress] =useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = React.useRef(() => {});

  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(5);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const { loading } = useLoading();
 const color='#fff'
//   return loading ? <div className="spinner"> <ClipLoader
//   color={color}
//   loading={loading}
//   cssOverride={override}
//   size={150}
//   aria-label="Loading Spinner"
//   data-testid="loader"
// /></div> : null;
return (
  loading ? <div className="spinner">
  <strong>Loading ...</strong>
  <Box sx={{ width: '150px' }}>
    <Box sx={{ width: '150px' }}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} sx={{ height:'20px' }} />
    </Box>
  </Box>
  </div>:null
);
};

export default Spinner;
