import React,{forwardRef} from 'react'
import "./howitWork.css";
import { useTranslation } from "react-i18next";

const HowitWork =  forwardRef((props, ref)=> {
  const { t } = useTranslation();

  return (
    <div ref={ref}>
        <section className="how-it-works py-5">
            <div className="container">
              <h2 className='text-center display-4 fw-semibold mb-2'>{t("HiwHeader")}</h2>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="text-center mb-0 mt-md-5 pt-5">
                        <span className='top-circlep-3 rounded-circle'></span>
                        {/* <img loading="lazy" className='img-fluid' src="/images/fill-the-form.png" alt="" /> */}
                        <img loading="lazy" className='start-0 bottom-0 img-fluid' src="/images/work-left-1.png" alt="" />
                      </div>
                      <div className='py-1 ps-md-4 d-flex align-items-start gap-2'>
                        <h3 className='steps rounded-circle  fw-semibold p-2 text-white'>01</h3>
                        <div>
                        <h3 className='fw-semibold'>{t("HiwH1")}</h3>
                        <p>{t("HiwP1")}</p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4">
                      <div className="text-center mb-md-5 mt-1 pb-4">
                        <span className='top-circle ellipse p-3 rounded-circle'></span>
                        {/* <img loading="lazy" className='img-fluid' src="/images/get-unlock-code.png" height={221} alt="" /> */}
                        <img loading="lazy" className=' start-0 bottom-0 img-fluid' src="/images/work-left-2.png" alt="" />
                      </div>
                      <div className='py-1 ps-md-4 d-flex align-items-start gap-2'>
                        <h3 className='steps rounded-circle fw-semibold p-2 text-white'>02</h3>
                        <div>
                        <h3 className='fw-semibold'>{t("HiwH2")}</h3>
                        <p>{t("HiwP2")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className=" text-center mb-md-5 mt-5">
                        <span className='top-circle p-3 rounded-circle'></span>
                        {/* <img loading="lazy" className='img-fluid'  src="/images/put-and-unlock.png" height={221} alt="" /> */}
                        <img loading="lazy" className='start-0 bottom-0 img-fluid' src="/images/work-left-3.png" alt="" />
                      </div>
                      <div className='py-1 ps-md-4 d-flex align-items-start gap-2'>
                        <h3 className='steps rounded-circle  fw-semibold p-2 text-white'>03</h3>
                        <div>
                        <h3 className='fw-semibold'>{t("HiwH3")}</h3>
                        <p>{t("HiwP3")}</p>
                        </div>
                      </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
  )
})

export default HowitWork