import React, { useContext, useState, useEffect } from "react";
import classNames from "classnames";
import "./header.css";
import "react-phone-number-input/style.css";
import { Link, useLocation } from "react-router-dom";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../_shared/LanguageContext";
import { getVisitorIP } from "../../_services/unlockPhone";
const Header = ({
  onComponent1ButtonClick,
  onComponent2ButtonClick,
  onComponent3ButtonClick,
  onComponent4ButtonClick,
}) => {
  const [activeItems, setActiveItems] = useState(Array(5).fill(false));
  const { handleLanguageChange } = useContext(LanguageContext);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const additionalClass = currentLanguageCode === "en" ? "x" : "";
  const additionalClass1 = currentLanguageCode === "es" ? "x" : "";
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  const navItems = [
    { path: "/unlock-phone", label: t("FooH11") },
    { path: "/imei-blacklist-checker", label: t("FooH12") },
    {
      path: "/icloud-activation-lock-checker",
      label: t("FooH13"),
    },
    {
      path: "/original-network-identifier",
      label: t("FooH14"),
    },
    {
      path: "https://www.jdoqocy.com/click-101030372-13828285?url=https%3A%2F%2Fwww.tenorshare.com%2Fproducts%2F4ukey-unlocker.html&cjsku=1016",
      label: t("FooH15"),
    },
  ];
  const location = useLocation();
  const getItemClassName = (path) => {
    return classNames("dropdown-item py-2", {
      active: location.pathname === path,
    });
  };
  useEffect(() => {
   
    getVisitorIP().then((res) => {
      console.log(res);
      if (res.country_code === "ES") {
        handleLanguageChange("es");
      } else {
        handleLanguageChange("en");
      }
    });
  }, []);
  const isMatched=[
    "/unlock-phone",
    "/imei-blacklist-checker",
    "/icloud-activation-lock-checker",
    "/original-network-identifier",
    "/about-us",
    "/success",
    "/how-it-works",
    "/testimonial",
    "/blogs",
    "/track-order",
    "/contact-us",
    "/imei-status",
    "/pages-error404"
  ]
  const condition = isMatched.some(path => location.pathname.startsWith(path));
  
  return (
    <div>
      <section className="top-bar bg-dark text-white">
        <div className="container pt-0 pb-2">
          <div className="row align-items-center">
            <div className="col-8 pt-2 pt-md-0 col-md-4">
              <i className="fas fa-at"></i> support@unlockriver.com
            </div>
            <div className="col-4 px-0 col-md-4 text-center">
              <ul className="list-inline mb-0">
                <li className="list-inline-item m-0 me-md-3">
                  <Link
                    to={"https://www.facebook.com/unlockriver"}
                    className="btn btn-dark"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li className="list-inline-item m-0 me-md-3">
                  <Link to={""} className="btn btn-dark">
                    <i className="fab fa-youtube"></i>
                  </Link>
                </li>
                <li className="list-inline-item m-0 me-md-3">
                  <Link
                    to={"https://www.instagram.com/unlockriver/"}
                    className="btn btn-dark"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 text-center">
              <button
                type="button"
                href="#"
                className={`btn-eng text-white mt-1 rounded border-0 ${additionalClass}`}
                onClick={() => {
                  // window.location.reload();
                  handleLanguageChange("en");
                }}
              >
                <img loading="lazy" src="/images/en.svg" alt="English" />
                English
              </button>
              <span className="mx-2">|</span>
              <button
                type="button"
                href="#"
                className={`btn-eng text-white mt-1 rounded border-0 ${additionalClass1}`}
                onClick={() => {
                  // window.location.reload();
                  handleLanguageChange("es");
                }}
              >
                <img loading="lazy" src="/images/es.svg" alt="Spanish" />
                Spanish
              </button>
            </div>
          </div>
        </div>
      </section>

      <header className="bg-transparent position-relative z-1">
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/unlock-phone">
                  <img
                    loading="lazy"
                    src="/images/unlock-logo.png"
                    alt="Unlock River"
                  />
                </Link>
                <button
                  className={`${condition?'xy':'yz'} navbar-toggler border-0 ${
                    isHidden ? "collapsed" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={isHidden ? "true" : "false"}
                  aria-label="Toggle navigation"
                  onClick={toggleVisibility}
                >
                  <span className={'navbar-toggler-icon'}></span>

                </button>
                <div
                  className={`collapse navbar-collapse ${
                    isHidden ? "show" : ""
                  }`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {t("Header1")}
                      </Link>
                      <ul
                        className="dropdown-menu p-0 service"
                        aria-labelledby="navbarDropdown"
                      >
                        {navItems.map((item, index) => (
                          <li key={index}>
                            <Link
                              className={getItemClassName(item.path)}
                              to={item.path}
                              onClick={toggleVisibility}
                            >
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        onClick={toggleVisibility}
                        to={"/faq"}
                      >
                        {t("Header2")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        onClick={toggleVisibility}
                        to={"/how-it-works"}
                      >
                        {t("Header3")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to={"/testimonial"}>
                        {t("Header4")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        onClick={toggleVisibility}
                        to={"/contact-us"}
                      >
                        {t("Header5")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        onClick={toggleVisibility}
                        to={"/blogs"}
                      >
                        {"Blogs"}
                      </Link>
                    </li>
                  </ul>
                  <div
                    className="d-flex gap-2 me-md-5 checkorder-status"
                    role="search"
                  >
                    <Link to={"/track-order"} onClick={toggleVisibility}>
                      <button  className={condition ? 'btn btn-outline-light order-status' : 'btn btn-outline-light order-status withoutbg'}>
                        {t("Header6")}
                      </button>
                    </Link>
                    <button
                      className="btn bg cart-btn rounded-circle d-none d-md-none d-lg-block"
                      type="submit"
                    >
                      {condition? <i className="fas fa-shopping-cart text-white"></i>:<img src="/images/cart.svg" alt="cart"/>}
                    </button>
                  </div>
                  <div className="social-menu row align-items-center d-block d-md-block d-lg-none">
                    <div className="col-md-4 text-center">
                      <button
                        type="button"
                        href="#"
                        className={` mt-1 rounded border-0 ${additionalClass}`}
                        onClick={() => {
                          // window.location.reload();
                          toggleVisibility();
                          handleLanguageChange("en");
                        }}
                      >
                        <img
                          className="mx-1"
                          loading="lazy"
                          src="/images/en.svg"
                          alt="English"
                        />
                        English
                      </button>
                      <span className="mx-0">|</span>
                      <button
                        type="button"
                        href="#"
                        className={` mt-1 rounded border-0 ${additionalClass1}`}
                        onClick={() => {
                          // window.location.reload();
                          toggleVisibility();
                          handleLanguageChange("es");
                        }}
                      >
                        <img
                          className="mx-1"
                          loading="lazy"
                          src="/images/es.svg"
                          alt="Spanish"
                        />
                        Spanish
                      </button>
                    </div>
                    <div className="col-12">
                      <a
                        href="mailto:support@unlockriver.com"
                        className="text-center text-dark"
                        onClick={toggleVisibility}
                      >
                        {" "}
                        <i className="fas fa-at"></i> support@unlockriver.com{" "}
                      </a>
                    </div>
                    <div className="col-12 text-center">
                      <ul className="list-inline mt-2">
                        <li className="list-inline-item m-0 me-md-3">
                          <Link
                            to={"https://www.facebook.com/unlockriver"}
                            className="btn btn-white"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item m-0 me-md-3">
                          <Link to={""} className="btn btn-white">
                            <i className="fab fa-youtube"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item m-0 me-md-3">
                          <Link
                            to={"https://www.instagram.com/unlockriver/"}
                            className="btn btn-white"
                          >
                          <i className="fab fa-instagram"></i>
                           
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
