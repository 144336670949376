import React, { useState, useRef ,useEffect} from "react";
import "./imei-blacklist.css";
import "./../header/header.css";
import "react-phone-number-input/style.css";
import { imeiCheckout } from "../../_services/unlockPhone";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import CheckoutIMEI from "../stripe/CheckoutIMEI";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "js-cookie";
const ImeiBlacklist = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState(null);
  const [IMEI, setIMEI] = useState(null);
  const [data, setData] = useState(null);
  const { startLoading, stopLoading } = useLoading();
  const [valid_token, setValidToken] = useState([]);
  const captchaRef = useRef(null);

  const SECRET_KEY = "6LcjP2spAAAAAPk0RY2IdFqOEDGz0OwxoXALjjbS";
  const SITE_KEY = "6LcjP2spAAAAAGEdDTXPvavTKTSHVq2lo5jItpk8";
  // const SECRET_KEY = "6Lc17WopAAAAAM1JiMaX2uHPSlOMBvpD6ltTGp4k";
  // const SITE_KEY = "6Lc17WopAAAAAB_CC3Fux6_bCfj3p1uUergN3Wht";

  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);

  useEffect(() => {
    const imeiInfo = Cookies.get("imeiInfo");
    if (imeiInfo) {
      setData(JSON.parse(imeiInfo));
    }
  }, []);

  const Imei = Yup.object().shape({
    imei: Yup.string()
      .required(t("IEMIE1"))
      .test("isValidIMEI", t("IEMIE2"), (value) => {
        console.log(value);
        return isValidIMEI(value);
      }),
  });
  const isValidIMEI = (imei) => {
    if (/^\d{15}$/.test(imei)) {
      const imeiDigits = imei.split("").map(Number);
      const checksum = imeiDigits.pop();
      const sum = imeiDigits.reduce((acc, digit, index) => {
        const doubledDigit = index % 2 === 0 ? digit : digit * 2;
        return acc + (doubledDigit > 9 ? doubledDigit - 9 : doubledDigit);
      }, 0);
      return (10 - (sum % 10)) % 10 === checksum;
    }

    return false;
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      let token = captchaRef.current.getValue();
      captchaRef.current.reset();

      if (token) {
        startLoading();

        let valid_token = await verifyToken(token);
        setValidToken(valid_token);

        if (valid_token[0].success === true) {
          setIMEI(values.imei);
          const res = await imeiCheckout(values.imei);
          console.log(res);
          if (res) {
            setClientSecret(res.clientSecret);
            // openDialogBox();
          } else {
            console.error("Unexpected response format:", res);
          }
        } else {
          console.log("not verified");
          toast.error("Sorry!! Verify you are not a bot");
        }
      } else {
        toast.error("Sorry!! Verify you not are a bot");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error("Something went wrong");
    } finally {
      stopLoading();
    }
  };
  const verifyToken = async (token) => {
    let APIResponse = [];

    try {
      let response = await Axios.post(
        `${process.env.REACT_APP_API_URL}/verifyToken`,
        {
          reCAPTCHA_TOKEN: token,
          Secret_Key: SECRET_KEY,
        }
      );
      APIResponse.push(response["data"]);
      toast.success(t("IEMIS1"));
      return APIResponse;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-5 pt-5 pb-0 position-relative">
        <div className="container py-5">
          <div className="row  p-3">
            <div className="col-md-12 px-0">
              <h1 className="fw-bold display-2 text-center mt-md-5">
                {t("IEMIH1")}
              </h1>
              <p className="text-center imeiblacklist balance pb-2">
                {t("IEMIP1")}
              </p>
              {data && !clientSecret && (
                <p className="text-center imeiblacklist balance">
                  if you want to check old info please click here{" "}
                  <Link style={{ color: "#6a5696" }} to={"/imei-status"}>
                    Veiw Old Info
                  </Link>
                </p>
              )}
            </div>
          </div>
          <div className="row imeiblacklist-form ">
            <div className="col-md-12 ">
              <Formik
                initialValues={{
                  imei: "",
                }}
                validationSchema={Imei}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <>
                    <Form>
                      <div className="card unlock-card my-md-4 my-1">
                        <div className="card-body d-sm-flex d-md-flex d-lg-flex">
                          <div className="input-group border w-75">
                            <label
                              className="input-group-text border-0"
                              htmlFor="inputGroupSelect01"
                            >
                              <img
                                loading="lazy"
                                src="/images/ph_phone-call (1).svg"
                                alt=""
                              />
                            </label>
                            <Field
                              type="text"
                              className="form-control border-0 py-1"
                              placeholder={t("IEMIPC1")}
                              aria-label={t("IEMIPC1")}
                              id="imei"
                              name="imei"
                              value={values.imei}
                              onChange={handleChange}
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-lg ms-md-3 mt-md-0 mt-2 w-25 unlock-btn"
                          >
                            {t("IEMIB1")}
                          </button>
                        </div>
                        <ErrorMessage
                          name="imei"
                          component="div"
                          className="error-message ps-5 ms-4"
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik>

              {!clientSecret && (
                <div>
                  <ReCAPTCHA
                    className="recaptcha"
                    sitekey={SITE_KEY}
                    ref={captchaRef}
                  />
                </div>
              )}
            </div>

            {clientSecret && IMEI && (
              <div className="col-md-6 rounded bg-light p-4 m-auto">
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret: clientSecret }}
                >
                  <CheckoutIMEI
                    IMEI={IMEI}
                    serviceId={3}
                    clientSecret={clientSecret}
                  />
                </Elements>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImeiBlacklist;
