import React, { useState } from "react";
import "../success/Success.css";
import "./trackOrder.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import OrderServices from "../../_services/orderServices";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(106, 86, 150) 0%, rgb(106, 86, 150) 50%, rgb(106, 86, 150) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(106, 86, 150) 0%, rgb(106, 86, 150) 50%, rgb(106, 86, 150) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(106, 86, 150) 0%, rgb(106, 86, 150) 50%, rgb(106, 86, 150) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(106, 86, 150) 0%, rgb(106, 86, 150) 50%, rgb(106, 86, 150) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LocalShippingIcon />,
    2: <LocalShippingIcon />,
    3: <LocalShippingIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

// const steps = ['Order Received', 'Processing', 'Delivered'];

const steps = [
  {
    id: "Order Received",
    value:
      "Your order is successfully received, and we're preparing to process it. You'll receive an email confirmation shortly, acknowledging that your request is in our system.",
  },
  {
    id: "Processing",
    value:
      "We are actively working on generating your personalized unlock code. Once ready, we will promptly send it to your email address.",
  },
  {
    id: "Delivered",
    value:
      "Great news! Your unlock code is ready. Check your email for a comprehensive guide on how to use the code to unlock your phone effortlessly.",
  },
];
const TrackOrder = () => {
  const [order, setOrder] = useState(null);
  const { startLoading, stopLoading } = useLoading();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  console.log(order);
  const Imei = Yup.object().shape({
    orderId: Yup.string()
      .required("*orderId is required")
      .test("isValidOrderId", "Invalid Order Id format", (value) => {
        return value && value.length > 4;
      }),
  });
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      startLoading();
      const res = await OrderServices.getOrderById(values.orderId, {});
      setOrder(res);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      stopLoading();
    }
  };

  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-5 pt-5 pb-3 position-relative">
        <div className="container py-md-5 pt-5">
          <div className="row  p-3">
            <div className="col-md-12 px-0">
              <h1 className="fw-bold display-2 text-center mt-md-5">
                {t("toh")}
              </h1>
              <p className="text-center">{t("top1")}</p>
            </div>
          </div>
          <div className="row justify-content-center pt-md-3 pt-0 px-0">
            <div className="col-md-12">
              <Formik
                initialValues={{
                  orderId: "",
                }}
                validationSchema={Imei}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <>
                    <Form>
                      <div className="card unlock-card my-md-4 my-1">
                        <div className="card-body d-sm-flex d-md-flex d-lg-flex">
                          <div className="input-group border w-75">
                            <label
                              className="input-group-text border-0"
                              htmlFor="inputGroupSelect01"
                            >
                              <img
                                loading="lazy"
                                src="/images/place-order.svg"
                                alt=""
                                width={35}
                              />
                            </label>
                            <Field
                              type="text"
                              className="form-control border-0 py-1"
                              placeholder={t("top2")}
                              aria-label="orderId"
                              id="orderId"
                              name="orderId"
                              value={values.orderId}
                              onChange={handleChange}
                            />
                          </div>

                          <button
                            type="submit"
                            className="btn btn-lg ms-md-3 mt-md-0 mt-2 w-25 unlock-btn"
                          >
                            {t("top3")}
                          </button>
                        </div>
                        <ErrorMessage
                          name="orderId"
                          component="div"
                          className="error-message ps-5 ms-4"
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
          {order && !order.message && (
            <div className="row justify-content-center pt-md-3 px-2">
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                <h5 className="mb-0">{t("top4")}</h5>
                <h5 className="mb-0 fw-bold">{order?.customerEmail}</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                <h5 className="mb-0">{t("top5")}</h5>
                <h5 className="mb-0 fw-bold">{order?.invoice}</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                <h5 className="mb-0">{t("top6")}</h5>
                <h5 className="mb-0 fw-bold">{order?.status}</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-center p-3 mt-3 unlock-card">
                {order.PaymentMethod==="Crypto Payment" &&  (
                  <button
                    type="submit"
                    className="btn btn-lg ms-md-3 mt-md-0 mt-2 px-5 unlock-btn"
                    onClick={openModal}
                  >
                    Crypto Instruction
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      {order && !order.message && (
        <section>
          <div className="container">
            <div className="row py-5">
              <div className="col-12">
                <h2 className="fw-bold py-2 pe-5 pe-md-0 py-md-5 text-center">
                  Track Your Order
                </h2>
                <Stack sx={{ width: "100%" }} spacing={4}>
                  <Stepper
                    className="row d-flex track-your-order"
                    alternativeLabel
                    activeStep={1}
                    connector={<ColorlibConnector />}
                  >
                    {steps.map((label) => (
                      <Step
                        className="col-12 col-md-4 flex-auto"
                        key={label.id}
                      >
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          <h3>{label.id}</h3>
                          <p>{label.value}</p>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
              </div>
            </div>
          </div>
        </section>
      )}
<Dialog className="dialog dialog-box" open={isModalOpen}>
        <div className="text-center close-btn-cus">
          <span className="float-end mt-3" onClick={closeModal}>
            <img
              loading="lazy"
              src="/images/close-dialog.svg"
              alt="close"
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>

        <DialogTitle className="title">{t("UnpHead")}</DialogTitle>
        <div className="bitcoin_thankyou">
          <p className="bitcoin_thankyou_desc">{t("UnpB1")}</p>
          <ul>
            <li>
              <b>Step 1: </b> {t("UnpB2")}
            </li>
            <li>
              <b>Step 2: </b> {t("UnpB3")}
              <ul className="bitcoin_thankyou_inner_list ">
                <li>
                  <img
                    loading="lazy"
                    src="/images/logos_bitcoin.svg"
                    width={20}
                    alt="Bitcoin"
                  />
                  :{t("UnpB4")}
                </li>
                <li>
                  <img
                    loading="lazy"
                    src="/images/logos_ethereum.svg"
                    width={20}
                    alt="Ethereum"
                  />
                  :{t("UnpB5")}
                </li>
                <li>
                  <img
                    loading="lazy"
                    src="/images/logos_solana.svg"
                    width={20}
                    alt="Solana"
                  />
                  :{t("UnpB6")}
                </li>
              </ul>
            </li>
            <li>
              <b>Step 3: </b>
              {t("UnpB7")}
            </li>
          </ul>
          <p>{t("UnpB8")}</p>
        </div>
      </Dialog>

    </div>
  );
};
export default TrackOrder;
