import React, { useState, useEffect } from "react";
import "./Videos.css";
import cookies from "js-cookie";

export const Videos = () => {
  const [videoEmbedId, setVideoEmbedId] = useState("");
  const currentLanguageCode = cookies.get("i18next") || "en"; 

  useEffect(() => {
    // Set the appropriate video embed ID based on the current language code
    if (currentLanguageCode === "en") {
      setVideoEmbedId("8pgmaamqci");
      console.log('englisVideo')
    } else {
      setVideoEmbedId("hakli95f9n");
      console.log('spanish Vedio')
    }
  }, [currentLanguageCode]);

  return (
    <div>
      <div className="top-seprator"></div>
      <section className="videos-slider py-md-5 pt-5 pb-0">
        <div className="container pb-5">
          <h2 className="text-center display-4 fw-bold mb-5 check-out-video">
            {currentLanguageCode==='en'? ('Their words give us satisfaction'):('Mira estos vídeos')}
            
          </h2>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}
            >
              <div
                className={`wistia_embed wistia_async_${videoEmbedId} seo=true videoFoam=true`}
                style={{ height: "100%", position: "relative", width: "100%" }}
              >
                <div
                  className="wistia_swatch"
                  style={{
                    height: "100%",
                    left: 0,
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    top: 0,
                    transition: "opacity 200ms",
                    width: "100%",
                  }}
                >
                  <img
                    src={`https://fast.wistia.com/embed/medias/${videoEmbedId}/swatch`}
                    style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%" }}
                    alt=""
                    aria-hidden="true"
                    onLoad={(e) => (e.target.parentNode.style.opacity = 1)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Videos;

