import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./unlock-phone.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import CheckoutForm from "../stripe/CheckoutForm";
import { useLoading } from "../../_shared/LoadingContext";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { CopyToClipboard } from "react-copy-to-clipboard";
// for calculator
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { currencyExchange } from "../../_services/unlockPhone";

const UnlockPhone = ({ productData, formValue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "en";
  let website;
  let trustboxlang;
  if (currentLanguageCode === "en") {
    website = "https://www.trustpilot.com/review/www.unlockriver.com";
    trustboxlang = "en-US";
    console.log('from UnlockPhone')
  } else {
    trustboxlang = "es-ES";
    website = "https://es.trustpilot.com/review/www.unlockriver.com";
    console.log('from UnlockPhone')

  }
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);
  const [clientSecret, setClientSecret] = useState(null);
  const [selected, setSelected] = useState("");
  const { startLoading, stopLoading } = useLoading();
  const [customer, setCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState("00.000");
  const [amount, setAmount] = useState(`${productData[0]?.price}`);
  const [currency, setCurrency] = useState("2781");
  const [selectedOption, setSelectedOption] = useState({
    value: "1",
    label: "BTC",
  });
  console.log(productData);
  useEffect(() => {
    console.log(typeof amount)
    currencyExchange(amount, selectedOption.value, currency).then((res) => {
      console.log(res);
      setData(res?.price);
    });
  }, []);
  const fetchClientSecret = async () => {
    try {
      startLoading();
      console.log(formValue);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product: productData[0],
            customer: formValue,
            languageId: currentLanguageCode,
          }),
        }
      );

      const { clientSecret, customer } = await response.json();
      console.log(clientSecret, customer);
      setClientSecret(clientSecret);
      setCustomer(customer);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  const bitcoinOrder = async () => {
    try {
      startLoading();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/bitcoinOrder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product: productData[0],
            customer: formValue,
            languageId: currentLanguageCode,
          }),
        }
      );

      // Check if the response is OK
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message) {
          toast.success(responseData.message);
          navigate("/success", {
            state: {
              order: responseData.orderId,
            },
          });
        }
      } else {
        // If response is not OK, throw an error
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error during bitcoin order:", error);
      toast.error("Error during bitcoin order:", error);
    } finally {
      stopLoading();
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const selectPaymentMethod = async (type) => {
    console.log(clientSecret);
    setSelected(type);
    if (type === "card" && Object.keys(formValue).length > 0) {
      await fetchClientSecret();
    } else if (type === "bitcoin" && Object.keys(formValue).length > 0) {
      openModal();
      // await bitcoinOrder();
    }
  };
  const options = [
    { value: "1", label: "BTC" },
    { value: "5426", label: "SOL" },
    { value: "1027", label: "ETH" },
  ];

  const handleCurrencyChange = (data, handleChange) => {
    console.log(data);
    setSelectedOption(data);
    handleChange("convert_id")(data.value);
    currencyExchange(amount, data.value, currency).then((res) => {
      console.log(res);
      setData(res?.price);
    });
  };
  const handleAmtChange = (data, handleChange) => {
    console.log(data.target.value);
    setAmount(data.target.value);
    handleChange("amount")(data.target.value);
    if (parseInt(data.target.value )> 0) {
      currencyExchange(data.target.value, selectedOption.value, currency).then(
        (res) => {
          console.log(res);
          setData(res?.price);
        }
      );
    }
  };
  return (
    <div>
      <div className="row p-3">
        <div className="col-md-12 col-lg-7 py-md-0 py-3 ps-0 pe-md-3 pe-0 accordian-col">
          <div className="accordion " id="faqAccordion">
            <div className="accordion-item mb-3 rounded">
              <div className="accordion-header">
                <button
                  className="accordion-button bg-transparent shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h6 className="fw-semibold">{t("UnpH1")}</h6>
                </button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  <h6 className="fw-semibold">{t("UnpH1P")}</h6>
                  <p className="lh-lg">
                    {t("UnpH1P1")}
                    <br />
                    {t("UnpH1P2")}
                    <br />
                    {t("UnpH1P3")}
                    <br />
                    {t("UnpH1P4")}
                    <br />
                    {t("UnpH1P5")}
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div
              className="accordion-item border-top rounded mb-3"
              id="faqAccordion1"
            >
              <div className="accordion-header">
                <button
                  className="accordion-button bg-transparent shadow-none collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h6 className="fw-semibold">{t("UnpH2")}</h6>
                </button>
              </div>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion1"
              >
                <div className="accordion-body">
                  <h6 className="fw-semibold">{t("UnpH1P")}</h6>
                  <p className="lh-lg">
                    {t("UnpH2P1")}
                    {/* <br />
                    {t("UnpH2P2")}
                    <br />
                    <ul>
                      <li>{t("UnpH2P2L1")}</li>
                      <li>{t("UnpH2P2L2")}</li>
                      <li>{t("UnpH2P2L3")}</li>
                    </ul> */}
                    <div className="copyright d-md-flex">
                      <Link
                        to={"/refund-policy"}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <p>{t("UnpH2P2L4")}</p>
                      </Link>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item border-top rounded mb-3">
              <div className="accordion-header">
                <button
                  className="accordion-button bg-transparent shadow-none collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h6 className="fw-semibold">{t("UnpH3")}</h6>
                </button>
              </div>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  <h6 className="fw-semibold">{t("UnpH3P")}</h6>

                  <p className="lh-lg">
                    {t("UnpH3P1")}
                    <br />
                    {t("UnpH3P2")}
                    <br />
                    {t("UnpH3P3")}
                    <br />
                    {t("UnpH3P4")}
                    <br />
                    {t("UnpH3P5")}
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 trustpilot d-flex flex-column justify-content-center ">
              <div>
                <img loading="lazy" src="/images/trustpilot-logo.png" alt="" />
                <div
                  className="trustpilot-widget"
                  data-locale={`${trustboxlang}`}
                  data-template-id="5613c9cde69ddc09340c6beb"
                  data-businessunit-id="544819a600006400057b1620"
                  data-style-height="100%"
                  data-style-width="100%"
                  data-theme="light"
                >
                  <Link to={website} target="_blank" rel="noopener">
                    Trustpilot
                  </Link>
                </div>
                <Link
                  className="btn btn-outline-light mt-4 px-5 py-3"
                  to={website}
                  target="_blank"
                  rel="noopener"
                >
                  {t("TestB1")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-5 bg-white rounded border mt-4 mt-md-0 order-details-col">
          <div className="row border-bottom p-3">
            <div className="col-md-5 ps-0">
              {productData[0] ? (
                <img
                  loading="lazy"
                  className="modal-img shadow"
                  alt={""}
                  src={`${process.env.REACT_APP_IMAGE_URL}${productData[0].image}`}
                  width="150"
                />
              ) : (
                <Skeleton variant="rectangular" width={150} height={150} />
              )}
            </div>
            <div className="col-md-7 px-0 py-md-0 py-3">
              <h6 className="fw-bold lh-base">
                {t("UnpV1")} {formValue.carrier}- {formValue.manufacturer}
              </h6>
              <h6 className="fw-normal lh-base">
                <span className="text-secondary">IMEI:</span> {formValue.imei}
              </h6>
              <h6 className="fw-normal lh-base">
                <span className="text-secondary">{t("UnpV2")} :</span>{" "}
                {formValue.carrier}
              </h6>
            </div>
          </div>
          <div className="row border-bottom p-3">
            <div className="col-md-12 p-0">
              <h6 className="fw-bold lh-base">{t("UnpC1")}</h6>
              <div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control rounded p-3"
                    placeholder={t("UnpC2")}
                    aria-label="code"
                    aria-describedby="button-addon2"
                  />
                  <button
                    className="btn mt-0 ms-2 unlock-btn rounded text-white py-3 px-4"
                    style={{ background: "#6A5696" }}
                    type="button"
                  >
                    {t("UnpC3")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom p-3 custom-our-price">
            <div className="col-md-8 ps-0 custom-our-price-right">
              <h4 className=" text-secondary lh-base pb-2">{t("UnpC4")}</h4>
              <h4 className=" text-secondary lh-base">{t("UnpC5")}</h4>
              {/* <p className="text-secondary lh-base">
                {t("UnpC5")} {productData[0].delivery_time}
              </p> */}
            </div>
            <div className="col-md-4 custom-our-price-right">
              <h4 className=" text-secondary lh-base cus-price-amount pb-2">
                ${productData[0].price} USD
              </h4>
              <h4 className="text-secondary lh-base cus-price-amount">
              {t("UnpC6")} {productData[0].delivery_time}{" "}
              </h4>
            </div>
          </div>
          <div className="row p-3 paymentsmethod">
            <div className="col-md-12 px-0">
              <h4 className="fw-bold lh-base select-payment-text">
                {t("UnpCP1")}
              </h4>

              <div
                className="btn-group btn-group-lg pb-3 pt-2 cus-opt-payment"
                role="group"
                aria-label="Large button group"
              >
                <input
                  onClick={() => selectPaymentMethod("card")}
                  type="radio"
                  name="paymentMethod"
                  id="card"
                  className="d-none"
                />
                <label
                  htmlFor="card"
                  className="btn text-center rounded me-1 px-md-4"
                >
                  <img
                    loading="lazy"
                    src="/images/ic_baseline-credit-card.svg"
                    width={20}
                    alt="Card"
                  />
                  <p className="m-0 cus-card-text">{t("UnpCP2")}</p>
                </label>
                {/* <input
                  onClick={() => selectPaymentMethod("paypal")}
                  type="radio"
                  name="paymentMethod"
                  id="paypal"
                  className="d-none"
                />
                <label
                  htmlFor="paypal"
                  className="btn text-center rounded me-1 px-md-4"
                >
                  <img
                    loading="lazy"
                    src="/images/logos_paypal.png"
                    width={20}
                    alt="paypal"
                  />
                  <p className="m-0 cus-card-text">PayPal</p>
                </label> */}
                <input
                  onClick={() => selectPaymentMethod("bitcoin")}
                  type="radio"
                  name="paymentMethod"
                  id="bitcoin"
                  className="d-none"
                />
                <label
                  htmlFor="bitcoin"
                  className="btn text-center rounded me-0 px-md-4"
                >
                  <img
                    loading="lazy"
                    src="/images/BTC.svg"
                    width={20}
                    alt="Bitcoin"
                  />
                  <p className="m-0 cus-card-text">Bitcoin</p>
                </label>
              </div>
              {clientSecret && selected === "card" && (
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret: clientSecret }}
                >
                  <CheckoutForm
                    customer={customer}
                    clientSecret={clientSecret}
                    productData={productData[0]}
                    formValue={formValue}
                  />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog className="dialog dialog-box" open={isModalOpen}>
        <div className="text-center close-btn-cus">
          <span className="float-end mt-3" onClick={closeModal}>
            <img
              loading="lazy"
              src="/images/close-dialog.svg"
              alt="close"
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>

        <DialogTitle className="title">{t("UnpHead")}</DialogTitle>
        <div className="bitcoin_thankyou">
          <p className="bitcoin_thankyou_desc">{t("UnpB1")}</p>
          <ul>
            <li>
              <b>Step 1: </b> {t("UnpB2")}
            </li>
            <b>Step 2: </b> {t("UnpB3")}
            <ul className="bitcoin_thankyou_inner_list ">
              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <p className="bitcoin_thankyou_desc">
                      {t('UnpB11')}
                      </p>
                     {t('UnpB12')}
                    </li>
                    <Formik
                      initialValues={{
                        amount: 1,
                        convert_id: "1",
                        id: "",
                      }}
                      onSubmit={"handleSubmit"}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                      }) => (
                        <>
                          <Form className="cal_form">
                            <div className="input-group mb-3 border py-1 d-flex justify-content-between">
                              <label
                                className="input-group-text border-0"
                                htmlFor="inputGroupSelect01"
                              >
                                <img
                                  loading="lazy"
                                  src="/images/en.svg"
                                  alt=""
                                />
                                <b>USD</b>
                              </label>
                              <Field
                                type="number"
                                className="form-control border-0 py-2"
                                id="amount"
                                name="amount"
                                value={amount}
                                onChange={(data) => {
                                  handleAmtChange(data, handleChange);
                                }}
                              />
                            </div>
                            {/* <div className=" mb-3 border py-1 d-flex justify-content-between">
                            <img
                              loading="lazy"
                              src="/images/r_arrow.svg"
                              alt=""
                            />
                            </div> */}
                            <div className="input-group mb-3 border py-1 d-flex justify-content-between">
                              <label
                                className="input-group-text border-0 cripto_label"
                                htmlFor="carrier"
                              >
                                <img
                                  src={`/images/${selectedOption.label}.svg`}
                                  alt=""
                                  style={{ padding: 0 }}
                                />
                              </label>
                              <Select
                                className="form-select-carrier1 border-0"
                                id="convert_id"
                                name="convert_id"
                                options={options}
                                defaultValue={selectedOption}
                                onChange={(data) => {
                                  setSelectedOption(data);
                                  handleCurrencyChange(data, handleChange);
                                }}
                                placeholder={t("FormField1")}
                              />
                            </div>

                            <div className="input-group mb-3 border py-1 d-flex justify-content-between">
                              <Field
                                type="number"
                                className="form-control border-0 py-2"
                                value={data}
                              />
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <img
                        loading="lazy"
                        src="/images/BTC.svg"
                        width={20}
                        alt="Bitcoin"
                      />
                      :{t("UnpB4")}
                      <CopyToClipboard
                        text={`bc1qpayjq7tqmd6z0375l5qwyn6aylm73kutne6jj0`}
                      >
                        <Link
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                      <br />
                      <img
                        loading="lazy"
                        className="p-4"
                        src="/images/1. Bitcoin QR Code Wallet.png"
                        alt="Bitcoin"
                        width={200}
                      />
                    </li>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <img
                        loading="lazy"
                        src="/images/ETH.svg"
                        width={20}
                        alt="Ethereum"
                      />
                      :{t("UnpB5")}{" "}
                      <CopyToClipboard
                        text={`0x9363829e7e06c10fb563f13E224feE691CE84256`}
                      >
                        <Link
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                      <br />
                      <img
                        loading="lazy"
                        className="p-4"
                        src="/images/2. Ethereum QR Wallet.png"
                        alt="Solana"
                        width={200}
                      />
                    </li>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <img
                        loading="lazy"
                        src="/images/SOL.svg"
                        width={20}
                        alt="Solana"
                      />
                      :{t("UnpB6")}{" "}
                      <CopyToClipboard
                        text={`3rFJdLrhg9FgCVsf2rEWFZKXMSG919uqNf69Uw8DPuvh`}
                      >
                        <Link
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                      <br />
                      <img
                        loading="lazy"
                        className="p-4"
                        src="/images/3. Solana QR Wallet.png"
                        alt="Solana"
                        width={200}
                      />
                    </li>
                  </div>
                </div>
              </div>
            </ul>
            <li>
              <b>Step 3: </b>
              {t("UnpB7")}
            </li>
          </ul>
          <p>{t("UnpB8")}</p>
          <p>{t("UnpB9")}</p>
          <div className="continue_bit">
            <Link onClick={bitcoinOrder}>{t("UnpB10")}</Link>
          </div>
          <ToastContainer />
        </div>
      </Dialog>
    </div>
  );
};

export default UnlockPhone;
