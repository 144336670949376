import React from "react";
import { useTranslation } from "react-i18next";

const DeliveryPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="">
        <div className="container  trems-and-condition">
          <div className="row pt-md-5 justify-content-center">
            <div className="col-12 px-4">
              <div className="refund-eligibility">
              <h2 className="py-md-4 py-3 display-5 tc-head fw-bold">{t('dph1')}</h2>
                <p  className="tc-text my-md-4 my-2">
                {t('dpp1')}
                </p>
                <h2 className="tc-title fw-bold">{t('dph2')}</h2>
                    <p className="tc-text my-md-4 my-2">
                    {t('dpp2')}
                    </p>

                  <h2 className="tc-title fw-bold"> {t('dph3')}</h2>
                    <ul>
                      <li  className="py-2 tc-text">
                      {t('dpp3')}
                      </li>
                      <li  className="py-2 tc-text">
                      {t('dpp4')}
                      </li>
                    </ul>

                  <h2 className="tc-title fw-bold">{t('dph4')}</h2>
                    <ul>
                      <li  className="py-2 tc-text">
                      {t('dpp5')}
                      </li>
                      <li  className="py-2 tc-text">
                      {t('dpp6')}
                      </li>
                    </ul>

                  <h2 className="tc-title fw-bold">{t('dph5')}</h2>
                    <ul>
                      <li  className="py-2 tc-text">
                      {t('dpp7')}
                      </li>
                      <li  className="py-2 tc-text">
                      {t('dpp8')}
                      </li>
                    </ul>

                  <h2 className="tc-title fw-bold">{t('dph6')}</h2>
                    <p  className="tc-text my-md-4 my-2">
                    {t('dpp9')}
                    </p>
                    <ul>
                      <li  className="py-2 tc-text">
                      {t('dpp10')}
                      </li>
                      <li  className="py-2 tc-text">
                      {t('dpp11')}
                      </li>
                      <li  className="py-2 tc-text">
                      {t('dpp12')}
                      </li>
                    </ul>

                  <h2 className="tc-title fw-bold"> {t('dph7')}</h2>
                    <p  className="tc-text my-md-4 my-2">
                    {t('dpp13')}
                    </p>
                  <h2 className="tc-title fw-bold">{t('dph8')}</h2>
                    <p  className="tc-text my-md-4 my-2">
                    {t('dpp14')}
                    </p>

                  <h2 className="tc-title fw-bold">{t('dph9')}</h2>
                    <p  className="tc-text my-md-4 my-2">
                    {t('dpp15')}
                    </p>

                  <h2 className="tc-title fw-bold">{t('dph10')}</h2>
                    <p  className="tc-text my-md-4 my-2">
                    {t('dpp16')}
                    </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeliveryPolicy;
