import React, { useState, useRef } from "react";

import "./security-passcode.css";
import "react-phone-number-input/style.css";
import { Dialog, DialogTitle } from "@mui/material";
import { checkImei } from "../../_services/unlockPhone";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { useTranslation } from "react-i18next";

const SecurityPasscode = () => {
  const { t } = useTranslation();
console.log(t('IEMIH4'))
  const [openDialog, handleDisplay] = React.useState(false);
  const [info, setInfo] = React.useState(null);
  const { startLoading, stopLoading } = useLoading();
  const [valid_token, setValidToken] = useState([]);
  const captchaRef = useRef(null);

  const SECRET_KEY = "6LcjP2spAAAAAPk0RY2IdFqOEDGz0OwxoXALjjbS";
  const SITE_KEY = "6LcjP2spAAAAAGEdDTXPvavTKTSHVq2lo5jItpk8";
  // const SECRET_KEY = "6Lc17WopAAAAAM1JiMaX2uHPSlOMBvpD6ltTGp4k";
  // const SITE_KEY = "6Lc17WopAAAAAB_CC3Fux6_bCfj3p1uUergN3Wht";
  const handleClose = () => {
    handleDisplay(false);
  };
  const openDialogBox = () => {
    handleDisplay(true);
  };
  const Imei = Yup.object().shape({
    imei: Yup.string()
      .required(t('IEMIE1'))
      .test("isValidIMEI", t('IEMIE2'), (value) => {
        console.log(value)
        return isValidIMEI(value)
        
      }),
  });
  const isValidIMEI = (imei) => {
    if (/^\d{15}$/.test(imei)) {
      const imeiDigits = imei.split("").map(Number);
      const checksum = imeiDigits.pop();
      const sum = imeiDigits.reduce((acc, digit, index) => {
        const doubledDigit = index % 2 === 0 ? digit : digit * 2;
        return acc + (doubledDigit > 9 ? doubledDigit - 9 : doubledDigit);
      }, 0);
      return (10 - (sum % 10)) % 10 === checksum;
    }

    return false;
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      let token = captchaRef.current.getValue();
      captchaRef.current.reset();

      if (token) {
      startLoading();

        let valid_token = await verifyToken(token);
        setValidToken(valid_token);

        if (valid_token[0].success === true) {
            const res = await checkImei(values.imei);
            if (res) {
              setInfo(res);
              openDialogBox();
              resetForm();
            } else {
              console.error("Unexpected response format:", res);
            }
          
        } else {
          console.log("not verified");
          toast.error("Sorry!! Verify you are not a bot");
        }
      }else{
        toast.error("Sorry!! Verify you not are a bot");

      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error('Something went wrong');
    } finally {
      stopLoading();
    }
  };
  const verifyToken = async (token) => {
    let APIResponse = [];

    try {
      let response = await Axios.post(`${process.env.REACT_APP_API_URL}/verifyToken`, {
        reCAPTCHA_TOKEN: token,
        Secret_Key: SECRET_KEY,
      });
      APIResponse.push(response["data"]);
      toast.success(t('IEMIS1'));
      return APIResponse;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-5 pt-5 pb-0 position-relative">
        <div className="container py-5">
          <div className="row  p-3">
            <div className="col-md-12">
              <h1 className="fw-bold display-2 text-center mt-md-5 balance">
              {t('IEMIH4')}
              </h1>
              <p className="text-center imeiblacklist">
              {t('IEMIP4')}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Formik
                initialValues={{
                  imei: "",
                }}
                validationSchema={Imei}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <>
                    <Form>
                      <div className="card unlock-card my-4">
                        <div className="card-body d-sm-flex d-md-flex d-lg-flex">
                          <div className="input-group border w-75">
                            <label
                              className="input-group-text border-0"
                              htmlFor="inputGroupSelect01"
                            >
                              <img
                                loading="lazy"
                                src="/images/ph_phone-call (1).svg"
                                alt=""
                              />
                            </label>
                            <Field
                              type="text"
                              className="form-control border-0 py-1"
                              placeholder={t('IEMIPC1')}
                              aria-label={t('IEMIPC1')}
                              id="imei"
                              name="imei"
                              value={values.imei}
                              onChange={handleChange}
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-lg ms-md-3 mt-md-0 mt-2 w-25 unlock-btn"
                          >
                           {t('IEMIB1')}
                          </button>
                        </div>
                        <ErrorMessage
                          name="imei"
                          component="div"
                          className="error-message ps-5 ms-4"
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
              <div>
                <ReCAPTCHA
                  className="recaptcha"
                  sitekey={SITE_KEY}
                  ref={captchaRef}
                />
              </div>
            </div>
          </div>
        </div>
        <Dialog className="dialog" open={openDialog}>
          <div className="text-center">
            <img loading="lazy"  src="/images/unlock-logo.png" alt="" />
            <span className="float-end mt-3" onClick={handleClose}>
            <img
                  loading="lazy"
                  src="/images/close-dialog.svg"
                  alt="close"
                  style={{ cursor: "pointer" }}
                />
            </span>
          </div>

          <DialogTitle className="title">
          {t('IEMIH4')}
          </DialogTitle>
          <div className="row d-flex justify-content-between">
            <div className="col-5 text-start pt-3">
              <span className="">IMEI</span>
            </div>
            <div className="col-7 text-end pt-3">
              <span className="">8539375828501345</span>
            </div>
            <div className="col-6 text-start pt-3">
              <span className="">Find My iPhone:</span>
            </div>
            <div className="col-6 text-end pt-3">
              <span className="">ON</span>
            </div>
            <div className="col-6 text-start pt-3">
              <span className="">iCloud Status:</span>
            </div>
            <div className="col-6 text-end pt-3">
              <span className="text-success">Clean</span>
            </div>
            <div className="text-end d-flex justify-content-md-end">
              <button className="btn btn-sm mt-5 btn1">
                Check Carrier / Country
              </button>
              &nbsp;&nbsp;
              <button className="btn btn-sm mt-5 btn2">
                Buy iCloud Removed
              </button>
            </div>
          </div>
        </Dialog>
      </section>
    </div>
  );
};

export default SecurityPasscode;
