import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../../_shared/LoadingContext";
import "./CheckoutForm.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { purple } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TermsandConditions from "../../shared/T&C/T&c";
const style = {
  position: 'absolute',
  top: '3%',
  left: '3%',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  height: '94%',
    'overflow-x': 'hidden',
    'overflow-y': 'scroll',
  // p: 4,
};
const CheckoutForm = ({ clientSecret, formValue, customer }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();
  const { startLoading, stopLoading } = useLoading();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const handleSubmit = async (event) => {
    startLoading();
    event.preventDefault();
    if (!stripe || !elements || !isNameValid()) {
      stopLoading();
      return;
    }
    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            email: formValue.email,
          },
        },
        customer: customer.id,
      });
      handlePaymentResult(result);
    } catch (error) {
      handleError(error);
    } finally {
      stopLoading();
    }
  };

  const handlePaymentResult = (result) => {
    if (result.error) {
      console.error(result.error.message);
      toast.error(result.error.message);
      handleUnSuccessfulPayment(result)
    } else {
      handleSuccessfulPayment(result);
    }
  };
  const handleSuccessfulPayment = async (result) => {
    const addOrderResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/order/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: result.paymentIntent.id,
          clientSecret: clientSecret,
          // status: result.paymentIntent.status,
          status: "succeeded",
        }),
      }
    );

    const addOrderResult = await addOrderResponse.json();

    if (addOrderResult.orderId.status === "succeeded") {
      toast.success(addOrderResult.message);
      navigate("/success", {
        state: {
          order: addOrderResult.orderId,
        },
      });
    } else {
      navigate("/unlock-phone");
    }
  };
  const handleUnSuccessfulPayment = async (result) => {

    console.log(result)
    const addOrderResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/order/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId:clientSecret ,
          clientSecret: clientSecret,
          // status: result.paymentIntent.status,
          status: "Failed",
        }),
      }
    );

    const addOrderResult = await addOrderResponse.json();

    if (addOrderResult.orderId.status === "succeeded") {
      toast.success(addOrderResult.message);
      navigate("/success", {
        state: {
          order: addOrderResult.orderId,
        },
      });
    } else {
      navigate("/unlock-phone");
    }
  };
  const [agree, setAgree] = useState(false);

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const handleError = (error) => {
    console.error("Error in handleSubmit:", error);
    toast.error("An error occurred during payment. Please try again.");
  };

  const isNameValid = () => {
    if (name.trim() === "") {
      setNameError(t("ContE1"));
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          className="w-100 p-3 border rounded"
          placeholder={t("UnpF1")}
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {nameError && <div className="error">{nameError}</div>}
      </div>
      <CardElement />
      <button
        type="submit"
        className="btn text-white border-none fs-5 w-100 rounded px-5 fw-bold py-4 mt-3 cus-payment-btn"
        style={{ background: "#6A5696" }}
        disabled={!agree}
      >
        {t("UnpCP3")}
      </button>
      <div className="d-flex tremcondition">
        <Checkbox
          id="agree"
          checked={agree[0] && agree[1]}
          indeterminate={agree[0] !== agree[1]}
          onChange={checkboxHandler}
          sx={{
            color: purple[900],
            "&.Mui-checked": {
              color: purple[900],
            },
          }}
        />
        <label htmlFor="agree" className="border-0 ms-3">
          <h6 className="fw-normal lh-base pt-3">
            <span className="text-secondary">{t("UnpTC1")}</span>{" "}
            <Link className="text-dark" onClick={handleOpen}>{t("UnpTC2")}</Link>
          </h6>
        </label>
      </div>

      <ToastContainer />
    </form>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <TermsandConditions col={10}/>
          </Typography>
        </Box>
      </Modal>
    </>

  );
};

export default CheckoutForm;
