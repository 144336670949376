import React, { forwardRef, useState } from "react";
import "./faq.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import Stack from "@mui/material/Stack";
const Faq = forwardRef((props, ref) => {
  const [Length, SetLength] = useState(5);
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const faqs = [];

  for (let i = 2; i <= Length; i++) {
    faqs.push({
      question: `FaqQ${i}`,
      answer: `FaqA${i}`,
      id: `collapse${i}`,
    });
  }
  console.log(faqs);
  const loadMore = () => {
    if (showMore) {
      SetLength(5);
    } else {
      SetLength(17);
    }
    setShowMore(!showMore);
  };
  const questions = faqs.map((faq) => (
    <div className="accordion-item border-top rounded mb-3" key={faq.id}>
      <div className="accordion-header">
        <button
          className="accordion-button bg-transparent shadow-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${faq.id}`}
          aria-expanded="false"
          aria-controls={faq.id}
        >
          <h3 className="fw-semibold text-justify">{t(faq.question)}</h3>
        </button>
      </div>
      <div
        id={faq.id}
        className="accordion-collapse collapse"
        data-bs-parent="#faqAccordion"
      >
        <div className="accordion-body text-justify">{t(faq.answer)}</div>
      </div>
    </div>
  ));
  return (
    <div ref={ref}>
      <section className="faq py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-9 mx-auto">
              <h2 className="fw-bold display-3 text-center">{t("FaqH2")}</h2>
              <h2 className="text-secondary display-3 text-center">
                {t("FaqH1")}
              </h2>

              <div className="accordion mt-5" id="faqAccordion">
                <div className="accordion-item mb-3 rounded">
                  <div className="accordion-header">
                    <button
                      className="accordion-button bg-transparent shadow-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <h3 className="fw-semibold text-justify">{t("FaqQ1")}</h3>
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body text-justify">{t("FaqA1")}</div>
                  </div>
                </div>
                {questions}
              </div>
            </div>

            <div className="col-md-12 col-lg-6 text-center mx-auto">
              <Button
                variant="outlined"
                style={{ color: "#6a5696", borderColor: "#6a5696" }}
                startIcon={<RefreshIcon />}
                onClick={loadMore}
              >
                {showMore ? t('FaqB1') : t('FaqB2')}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Faq;
