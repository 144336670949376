import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="">
        <div className="container py-md-2 pt-2">
          <div className="row pt-md-5 justify-content-center">
            <div className="col-12 px-4">
              <div className="privacy-policy">
                  <h2 className="py-md-4 py-3 display-5 tc-head fw-bold">{t("pph1")}</h2>
                <p className="tc-text my-md-4 my-2">{t("ppp1")}</p>
                    <h2 className="tc-title fw-bold">{t("pph2")}</h2>

                    <ul className="py-2">
                      <li className="py-2 tc-text">{t("ppp2")}</li>
                      <li className="py-2 tc-text">{t("ppp3")}</li>
                      <li className="py-2 tc-text">{t("ppp4")}</li>
                    </ul>

                    <h2 className="tc-title fw-bold">{t("pph3")}</h2>

                    <p className="tc-text my-md-4 my-2">{t("ppp5")}</p>
                    <ul className="py-2">
                      <li className="py-2 tc-text">{t("ppp7")}</li>
                      <li className="py-2 tc-text">{t("ppp8")}</li>
                      <li className="py-2 tc-text">{t("ppp9")}</li>
                      <li className="py-2 tc-text">{t("ppp10")}</li>
                      <li className="py-2 tc-text">{t("ppp11")}</li>
                      <li className="py-2 tc-text">{t("ppp12")}</li>
                      <li className="py-2 tc-text">{t("ppp13")}</li>
                      <li className="py-2 tc-text">{t("ppp14")}</li>
                      <li className="py-2 tc-text">{t("ppp15")}</li>
                    </ul>
                    <h2 className="tc-title fw-bold">{t("pph4")}</h2> <p className="tc-text my-md-4 my-2">{t("ppp16")}</p>
                  
                    <h2 className="tc-title fw-bold">{t("pph5")}</h2> <p className="tc-text my-md-4 my-2">{t("ppp17")}</p>
                  
                    <h2 className="tc-title fw-bold">{t("pph6")}</h2> <p className="tc-text my-md-4 my-2">{t("ppp18")}</p>
                  
                    <h2 className="tc-title fw-bold">{t("pph7")}</h2> <p className="tc-text my-md-4 my-2">{t("ppp19")}</p>
                  
                    <h2 className="tc-title fw-bold">{t("pph8")}</h2> <p className="tc-text my-md-4 my-2">{t("ppp20")}</p>
                 
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
