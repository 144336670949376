import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const RefundPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="">
        <div className="container py-md-2 pt-2">
          <div className="row pt-md-5 justify-content-center">
            <div className="col-12 px-4">
              <div className="refund-eligibility">
                <h2 className="tc-head display-5 fw-bold py-md-4 py-3">
                  {t("rph8")}
                </h2>
                <p className="tc-text my-md-4 my-2">{t("rpp15")}</p>

                {/* <h2 className="tc-title fw-bold">{t("rph1")}</h2>

                <p className="tc-text my-md-4 my-2">{t("rpp1")}</p>
                <ul>
                  <li className="py-2 tc-text">{t("rpp2")}</li>
                  <li className="py-2 tc-text">{t("rpp3")}</li>
                  <li className="py-2 tc-text">{t("rpp4")}</li>
                </ul> */}

                <h2 className="tc-title fw-bold"> {t("rph2")}</h2>
                <h2 className="tc-title fw-bold  pt-4"> {t("rph21")}</h2>
                <br />
                <ul>
                  <li className="py-2 tc-text">
                    <strong style={{ "font-size": "28px" }}>
                      {t("rpp5").split(":")[0]}:{" "}
                    </strong>
                    {t("rpp5").split(":")[1]}
                  </li>
                  <li className="py-2 tc-text">
                    <strong style={{ "font-size": "28px" }}>
                      {t("rpp6").split(":")[0]}:{" "}
                    </strong>
                    {t("rpp6").split(":")[1]}
                  </li>
                  <li className="py-2 tc-text">
                    <strong style={{ "font-size": "28px" }}>
                      {t("rpp7").split(":")[0]}:{" "}
                    </strong>
                    {t("rpp7").split(":")[1]}
                  </li>
                  <li className="py-2 tc-text">
                    <strong style={{ "font-size": "28px" }}>
                      {t("rpp8").split(":")[0]}:{" "}
                    </strong>
                    {t("rpp8").split(":")[1]}
                  </li>
                </ul>
                <br />
                <br />

                <h2 className="tc-title fw-bold">{t("rph3")}</h2>
                <br />
                <ul>
                  <li className="py-2 tc-text">
                    <strong style={{ "font-size": "28px" }}>
                      {t("rpp9").split(":")[0]}:{" "}
                    </strong>
                    {t("rpp9").split(":")[1]}
                  </li>
                  <li className="py-2 tc-text">
                    <strong style={{ "font-size": "28px" }}>
                      {t("rpp10").split(":")[0]}:{" "}
                    </strong>
                    {t("rpp10").split(":")[1]}
                  </li>
                </ul>

                {/* <h2 className="tc-title fw-bold">{t("rph4")}</h2>
                <p className="tc-text my-md-4 my-2">{t("rpp11")}</p>
                <h2 className="tc-title fw-bold"> {t("rph5")}</h2>
                <p className="tc-text my-md-4 my-2">{t("rpp12")}</p>

                <h2 className="tc-title fw-bold">{t("rph6")}</h2>
                <p className="tc-text my-md-4 my-2">{t("rpp13")}</p>
                <h2 className="tc-title fw-bold">{t("rph7")}</h2> */}
                <br />
                <ul>
                  {" "}
                  <p className="tc-text my-md-4 my-2">{t("rpp16")}</p>
                  <br />
                  <p className="tc-text my-md-4 my-2">{t("rpp17")}</p>
                </ul>
              </div>
              <div className="refund-request my-3 p-md-5 p-3 text-center">
                <h2 className="refund-request-tilte fw-semibold px-md-5 balance py-4">
                  {t("rph9")}
                </h2>
                <Link
                  to={"/request-a-refund"}
                  className="btn refund-btn fw-bold mb-2"
                >
                  {t("rpb1")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RefundPolicy;
