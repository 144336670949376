import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import requests from "./httpServices";
const apiUrl = process.env.REACT_APP_API_URL
export const getAllCarrier = async (endpoint, options = {}) => {

  try {
    return requests.get(`/getAllCarrier`, options);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error);
  }
};
export const getManufacturerById = async (id, options = {}) => {

  try {
    return requests.get(`/getManufacturerById?id=${id}`, options);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error);
  }
};
export const getModelById = async (id, id1) => {
  try {
    return requests.get(`/getModelById?id=${id}&id1=${id1}`);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error);

  }
};
export const getProductById = async (id, id1) => {
  try {
    return requests.get(`/getProductById?id=${id}`);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error)
  }
};
export const checkImei=async (imei,serviceId,options = {})=>{
  try {
    return requests.get(`/checkImei?imei=${imei}&serviceId=${serviceId}`,options);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error)
  }
}
export const imeiCheckout=async (imei,options = {})=>{
  try {
    return requests.get(`/imeiCheckout?imei=${imei}`,options);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error)
  }
}
export const imeiHistory=async (historyId,email,options = {})=>{
  try {
    return requests.get(`/imeiHistory?historyId=${historyId}&email=${email}`,options);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error)
  }
}
export const getVisitorIP = async () => {
  try {
    return requests.get(`/ip`);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error)
  }
};
export const currencyExchange = async (amount,convert_id,id) => {
  try {
    return requests.get(`/currencyExchange?amount=${amount}&convert_id=${convert_id}&id=${id}`);
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.response.data.error)
  }
};


   
